<template>
  <div>
    <div v-if="block=='activate'" class="signin_container">
      <div class="signin_form">
        <div class="signin_form_container">
          <div class="wrapper-activation">
            <div class="authorization activation">

              <div class="authorization_form">
                <div class="authorization_label">Активация</div>

                <div class="authorization_inputs">
                  <div v-if="step==1" class="input_iin_container">
                    <input class="input_iin" v-model="account.iin" :placeholder="$t('IIN')" maxlength="12"/>
                    <span v-if="$v.account.iin.$dirty && $v.account.iin.$invalid" class="text-danger">{{
                        $t('IIN_required')
                      }}!</span>
                  </div>
                  <div class="input_iin_container" v-if="step==2">
                    <input class="input_iin" v-model="email" placeholder="Email"/>
                  </div>
                </div>


                <button class="authorization_btn_next" @click="signup">
                  <span>{{ $t('Further') }}</span>
                </button>
              </div>


            </div>
            <div v-html="$t('activate_text')" class="activate-text">
            </div>
          </div>
        </div>
        <div v-show="showModal" class="sign_up_modal">
          <div class="sign_up_modal_back">
          </div>
          <div class="sign_up_modal_front">
            <div class="sign_up_modal_text">
              <div class="sign_up_confirm_mail">{{ $t('You_need_to_confirm_your_email_to_continue') }}</div>
              <div class="sign_up_please_confirm">{{ $t('Please_check_your_mail') }}</div>
            </div>
            <div @click.prevent="resend" class="sign_up_not_receive">{{
                $t('I_did_not_receive_letter')
              }}
            </div>
            <a href="/signin" class="sign_up_close_form" @click="showModal = false"></a>
          </div>
        </div>
      </div>

    </div>
    <div v-if="block=='login'" class="signin_container">
      <div class="signin_form">
        <div class="signin_form_container">
          <div class="authorization">
            <div class="authorization_form">
              <div class="authorization_label">{{ $t('Authorization') }}</div>
              <div class="authorization_inputs">
                <div class="input_iin_container">
                  <input class="input_iin" v-model="account.iin" :placeholder="$t('IIN')" maxlength="12"/>
                  <span v-if="$v.account.iin.$dirty && $v.account.iin.$invalid"
                        class="text-danger">{{ $t('IIN_required') }}!</span>
                </div>
                <div class="input_pass_container_new">
                  <input :type="showPass ? 'text' : 'password'" v-model="account.password" class="input_pass_new"
                         :placeholder="$t('Password')"/>
                  <div class="input_pass_new_open" v-show="showPass" @click="showPass = false"/>
                  <div class="input_pass_new_close" v-show="!showPass" @click="showPass = true"/>
                  <span v-if="$v.account.password.$dirty && $v.account.password.$invalid"
                        class="text-danger">{{ $t('Password_required') }}!</span>
                </div>
                <div class="restore_password">{{ $t('Forgot_password') }}?
                  <router-link to="/recovery_password">{{ $t('Recover') }}</router-link>
                </div>
                <div class="remember_me">
                  <input type="checkbox"/>
                  <span class="remember_me_label">{{ $t('Remember_me') }}</span>
                </div>
              </div>
              <button :disabled="loading" class="authorization_btn_next" @click="signin">
                <span>{{ $t('Further') }}</span>
              </button>
              <!--            <div class="no_account">{{ $t('No_account') }}? <a href="/signup">{{ $t('Register') }}</a></div>-->
            </div>
          </div>
        </div>
        <div v-show="showModal" class="sign_up_modal">
          <div class="sign_up_modal_back">
          </div>
          <div class="sign_up_modal_front">
            <div class="sign_up_modal_text">
              <div class="sign_up_confirm_mail">{{ $t('You_need_to_confirm_your_email_to_continue') }}</div>
              <div class="sign_up_please_confirm">{{ $t('Please_check_your_mail') }}</div>
            </div>
            <div @click.prevent="resend" class="sign_up_not_receive">{{ $t('I_did_not_receive_letter') }}</div>
            <a href="/signin" class="sign_up_close_form"></a>
          </div>
        </div>
      </div>
      <!--    <div class="signin_logo">-->
      <!--      <div class="signin_logo_container"></div>-->
      <!--    </div>-->
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import Vue from "vue";
import {integer, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: 'Login',
  data() {
    return {
      step: 1,
      email: '',
      block:'login',
      loading: false,
      showPass: false,
      showModal: false,
      account: {
        iin: '',
        password: ''
      },
    }
  },
  methods: {
    signup() {
      if (this.step == 1) {
        this.account.password = this.account.iin
        this.$http.post(API_ROOT + `/api/auth/login`, this.account)
          .then((res) => {
            this.step = 2
            localStorage.setItem("token", res.body.token);
          })
          .catch((err) => {
            Vue.toastr({
              message: 'Error',
              description: err.body.message,
              type: 'error'
            })
          })
      }
      if (this.step == 2) {
        this.$http.post(API_ROOT + `/api/user/email/set`, {'email': this.email, 'token': this.emailToken})
          .then((res) => {
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            this.showModal = true
            Vue.toastr({
              message: 'Success',
              description: res.body.message,
              type: 'success'
            })


          })
          .catch((err) => {
            Vue.toastr({
              message: 'Error',
              description: err.body.message,
              type: 'error'
            })
          })
      }
    },
    resend() {
      this.email = ''
      this.$http.post(API_ROOT + `/api/auth/email/resend`, this.account)
        .then((res) => {
          Vue.toastr({
            message: 'Success',
            description: res.body.message,
            type: 'success'
          })
        })
        .catch((err) => {
          Vue.toastr({
            message: 'Error',
            description: err.body.message,
            type: 'error'
          })
        })
    },
    signin() {
      this.loading = true
      let postData = {
        iin: this.account.iin,
        password: this.account.password,
      }

      this.$v.$touch()
      if (this.$v.account.$invalid) return

      this.$http.post(API_ROOT + `/api/auth/login`, postData)
        .then((res) => {
          localStorage.setItem('token', res.body.token)
          localStorage.setItem('user', JSON.stringify(res.body.user))
          localStorage.setItem('has_email', res.body.has_email)
          if (res.body.has_email == false) {
            this.block = 'activate'
            this.step = 2
          } else {
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push({name: 'profile'})
            }

            if (!res.body.user.coins) {
              localStorage.setItem('coins', 0)
            } else {
              localStorage.setItem('coins', res.body.user.coins.active_coins)
            }

            this.$router.push({name: 'profile'})
          }

          this.loading = false;
          // this.$router.push({ name: 'profile' })
        })
        .catch((err) => {

          if (err.status === 422 && err.body.need_verification === true) {
            this.showModal = true
          }

          this.loading = false;
          console.log(err)
        })
    },
  },

  validations: {
    account: {
      iin: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12),
        integer,
      },
      password: {
        required,
        minLength: minLength(6),
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.wrapper-activation{
  .authorization_form{
   min-width: 320px;
    margin-right: 1rem;
  }
}
.activation{
  width: max-content;
}
.wrapper-activation{
  max-width: 670px;
  display: flex;
  justify-content: space-between;
}
.activate-text{
  margin-left: 1rem;
  padding: 20px 20px 20px 0;
}
.signin_form{
  width: 60%;
}
@media (max-width: 991px) {
  .wrapper-activation{
    flex-direction: column;
  }
}
@media (max-width: 628px) {
  .signin_container .signin_form .authorization{
    margin: 2rem auto !important;
  }
  .signin_form{
    width: 100%!important;
  }
}
@media (max-width: 400px) {
  .wrapper-activation{
    .authorization_form{
      min-width: unset;
    }
  }
}
.sign_up_not_receive{
  cursor: pointer;
  border-bottom: 1px solid white!important;
  height: 25px!important;
  width: max-content!important;
  text-align: center!important;
  display: block!important;
  padding: 0!important;
  margin-left: auto!important;
  margin-right: 2rem!important;
  margin-top: 1rem!important;
}
</style>
